import * as Tone from 'tone'

const pentaMinor = {
  F: ['F3', 'Gs3', 'C4', 'Cs4', 'Ds4', 'F4', 'Gs4'],
  G: ['G2', 'As2', 'D3', 'Ds3', 'F3', 'G3', 'As3'],
  D: ['D4', 'F4', 'A4', 'C5', 'Cs5', 'D5', 'F5'],
  Bb: ['As3', 'Cs4', 'F4', 'Fs4', 'Gs4', 'As4', 'Cs5'],
}

const pianoNotes = ['A', 'As', 'B', 'C', 'Cs', 'D', 'Ds', 'E', 'F', 'Fs', 'G', 'Gs'];
const pianoBuffers = {};

pianoNotes.forEach((key) => {
  const indexes = Array.from(Array(7).keys());
  indexes.forEach((i) => {
    if (!pianoBuffers[key + i]) {
      const buffer = new Tone.ToneAudioBuffer(`/assets/samples/piano/${key}${i}.wav`)
      pianoBuffers[key + i] = buffer;
    }
  })
})

let players = []
let effects = []
// TODO explore arpegiattor idea
// TODO up and down variation to bring more natural musical transition
// TODO not iterate over and over the same sequence but generate a new sequence after each once
const randomSequence = (key) => Array.from(Array(6).keys()).reduce((acc, item, i) => {
  const rand  = () => Math.floor(Math.random() * pentaMinor[key].length)
  const scale = pentaMinor[key]
  let randomPos = rand()
  while (acc.includes(scale[randomPos])) {
    randomPos = rand()
  }
  acc.push(scale[randomPos])
  return acc
}, [])

function playSequence (sequence) {
  return Promise.all([new Tone.Reverb(15).generate()])
    .then(([reverb]) => {
      const compressor = new Tone.Compressor().connect(Tone.Destination)
      reverb.connect(compressor)
      reverb.connect(Tone.Destination)
      const autoFilter = new Tone.AutoFilter(Math.random() / 100 + 0.01, 100, 2);
      autoFilter.connect(reverb)
      autoFilter.start()
      const playbackRate = 0.5
      const vol = new Tone.Volume(-15)
      vol.connect(autoFilter);
      vol.connect(Tone.Destination)
      const play = notes => {
        const note = notes[Math.floor(Math.random() * notes.length)]
        const buf = pianoBuffers[note]
        const source = new Tone.BufferSource(buf).set({playbackRate, fadeIn: 4, fadeOut: 4, curve: 'linear'}).connect(vol)
        source.onended = (s) => s.stop().dispose();
        source.start('+1', 0, buf.duration / playbackRate)

        Tone.Transport.schedule(() => {
          play(notes)
        },`+${buf.duration / playbackRate - 4 + Math.random() * 5 - 2.5}` )
      }

      play(sequence.slice(0, 1))
      play(sequence.slice(1, sequence.length - 1))
      play(sequence.slice(-1))
      effects.push(compressor, reverb, autoFilter, vol)
    })
}

function start() {
  const tone = Object.keys(pentaMinor)[Math.floor(Math.random() * 4)]
  const sequence = randomSequence(tone);

  sequence.forEach((note, i) => {
    const compressor = new Tone.Compressor().connect(Tone.Destination)
    const autoFilter = new Tone.AutoFilter(Math.random() / 100 + 0.01, 50, 2);
    autoFilter.connect(compressor);
    autoFilter.start()
    const vol = new Tone.Volume(-10);
    vol.connect(autoFilter);
    players.push([compressor, autoFilter, vol])
    const startAtTime = (i * 2) + Tone.Transport.progress;
    const playbackRate = 0.5;
    const play = time => {
      // TODO add more randomness to piano notes
      const duration = (Math.ceil(Math.random() * 6)) - (Math.floor(Math.random() * 12) / 100) * (Math.floor(Math.random() * 2) === 1 ? 1 : -1)
      const buf = pianoBuffers[note]
      const source = new Tone.BufferSource(buf).connect(vol);
      source.onended = (s) => s.stop().dispose();
      source.start('+1', 0, duration / playbackRate);
      Tone.Transport.schedule(play, `+${i + 6 - (Math.floor(Math.random() * 24) / 100) * (Math.floor(Math.random() * 2) === 1 ? 1 : -1)}`)
    }
    Tone.Transport.schedule(play, startAtTime)

    // Tone.Transport.start()
  })

  playSequence(sequence).then(() => Tone.Transport.start())
}

function stop() {
  Tone.Transport.cancel(Tone.Transport.progress)
  Tone.Transport.stop()
  players.forEach(([compressor, autoFilter, vol]) => {
    vol.disconnect(autoFilter).dispose()
    autoFilter.disconnect(compressor).dispose()
    compressor.disconnect(Tone.Destination).dispose()
  })

  const [ compressor, reverb, autoFilter, vol] = effects
  vol
    .disconnect(Tone.Destination)
    .disconnect(autoFilter)
    .dispose();
  autoFilter
    .stop()
    .disconnect(reverb)
    .dispose();
  reverb
    .disconnect(Tone.Destination)
    .disconnect(compressor)
    .dispose();
  compressor.disconnect(Tone.Destination).dispose()
  players = []
  effects = []
}

const startButton = document.getElementById('start-generation')
const stopButton = document.getElementById('stop-generation')
startButton.addEventListener('click', start)
stopButton.addEventListener('click', stop)
